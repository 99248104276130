import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';
import { forEach, isArray, isEmpty, isObject, isString } from 'lodash';

export class UserGroupService {
  @Inject(HttpService) private http!: HttpService;

  public postusergroup(param: Dict<any>): Promise<any> {
    return this.http.post('/api/user/group/users', param);
  }

  public getusergroup(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group', param);
  }

  public getusergroupById(id: string): Promise<any> {
    return this.http.get(`/api/user/group/${id}`);
  }

  public getHRCoreUsergroupById(id: string): Promise<any> {
    return this.http.get(`/api/user/group/hrcore/${id}`);
  }

  public getAllUserGroups():Promise<any>{
    return this.http.get('/api/user/group/list');
  }

  public getUserGroupsByPermission(param:Dict<any>):Promise<any>{
    return this.http.get(`/api/user/group/list`,param)
  }

  public batchGetUsers(param: Dict<any>): Promise<any> {
    return this.http.post('/api/user/group/userlist', param);
  }


  public batchGetUsersForHrCore(param: Dict<any>): Promise<any> {
    return this.http.post('/api/user/group/hrCore/userlist', param);
  }

  public getusergroupQuickusers(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group/quickusers', param);
  }

  public async deleteUserGroup(id: number): Promise<any> {
    const res = await this.http.delete(
      `/api/user/group/${id}`,
      {},
      { params: { type: 'response' } }
    );
    Notification({
      title: '成功',
      message: '删除成功！',
      type: 'success'
    });
    return;
  }

  public async deleteUserGroupForHRCore(id: number): Promise<any> {
    const res = await this.http.delete(
      `/api/user/group/hrcore/${id}`,
      {},
      { params: { type: 'response' } }
    );
    Notification({
      title: '成功',
      message: '删除成功！',
      type: 'success'
    });
    return;
  }


  public postHrSelect(): Promise<any> {
    return this.http.post(`/api/hrCore/search/condition/hrcore`, {
    });
  }
  public postUserCount( param: Dict<any>): Promise<any> {
    return this.http.post(`/api/hrCore/userCount`, {
      ...param
    });
  }




  public postcondition(type: string, param: Dict<any>): Promise<any> {
    return this.http.post(`/api/user/group/search/condition/${type}`, {
      ...param
    });
  }

  public putUserGroup(param: Dict<any>): Promise<any> {
    this.trimFields(param);
    return this.http.put('/api/user/group', param);
  }

  public putUserGroupForHRCore(param: Dict<any>): Promise<any> {
    this.trimFields(param);
    return this.http.post('/api/user/group/hrcore/post', param);
    // return this.http.put('/api/user/group/hrcore', param);
  }
  //创建修改群聊
  public saveOrUpdate(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/groupChat/saveOrUpdate', param);
  }
  //解散群聊
  public groupChatDelete(id: Dict<any>): Promise<any> {
    return this.http.delete(`/api/application/groupChat/dissolutionChat/${id}`);
  }
  //创建修改标签
  public groupTag(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/groupTag', param);
  }
  //删除标签
  public groupTagDelete(id: Dict<any>): Promise<any> {
    return this.http.delete(`/api/application/groupTag/${id}`);
  }
  //获取配置文件
  public getsetup(): Promise<any> {
    return this.http.get('/api/application/system/setup');
  }
  public deptTree(): Promise<any> {
    return this.http.get('/api/wxAddressBookSync/deptTree');
  }
  //保存拖动后的通讯录关系
  public wxDeptRelation(param: Dict<any>): Promise<any> {
    return this.http.post('/api/wxDeptRelation',param);
  }
  //同步通讯录关系到企业微信
  public syncOrg(): Promise<any> {
    return this.http.get('/api/wxAddressBookSync/syncOrg');
  }

  //根据分组id查人
  public groupQueryList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/user/group/query/list',param);
  }
  public postUserGroup(param: Dict<any>): Promise<any> {
    this.trimFields(param);
    return this.http.post('/api/user/group', param);
  }
  public postUserGroupForHRCore(param: Dict<any>): Promise<any> {
    this.trimFields(param);
    return this.http.post('/api/user/group/hrcore', param);
  }

  public searchUserGroupsAndUsersByKeyword(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group/hrcore/group_user/search', param);
  }

  public retrieveUserGroupDivisionUserCount(param: Dict<any>): Promise<any> {
    return this.http.post(`/api/user/group/hrcore/usercount`, param);
  }

  public retrieveUserGroupEffectiveUserList(param: Dict<any>): Promise<any> {
    return this.http.post(`/api/user/group/hrcore/userlist`, param);
  }

  public getRelatedApplicationsByUserGroupId(id: number): Promise<any> {
    return this.http.get(`/api/user/group/apps/${id}`);
  }
  
  // 自定义分组列表查询
  public getCustomGroupList(): Promise<any> {
    return this.http.get(`/wecom-video-admin/custom/group/list`);
  }
  
  // 自定义分组用户查询
  public getCustomGroupUsers(param: Dict<any>): Promise<any> {
    return this.http.get(`/wecom-video-admin/custom/group/${param.customGroupId}`);
  }
  
  // 删除分组用户
  public deleteCustomGroup(param: Dict<any>): Promise<any> {
    return this.http.delete(`/wecom-video-admin/custom/group/${param.customGroupId}`);
  }
  
  // 修改保存分组用户
  public updateCustomGroup(param: Dict<any>): Promise<any> {
    const formData = new FormData();
    !isEmpty(param.file) && formData.append('file', param.file[0].raw);
    formData.append('customGroupId', param.customGroupId);
    formData.append('owners', param.owners);
    formData.append('groupName', param.groupName);
    const headers = { 'Content-Type': 'multipart/form-data' };
    return this.http.put(`/wecom-video-admin/custom/group/update`, formData, undefined, { headers });
  }
  
  // 新增保存分组用户
  public addCustomGroup(param: any): Promise<any> {
    const formData = new FormData();
    formData.append('file', param.file[0].raw);
    formData.append('groupName', param.groupName)
    formData.append('owners', param.owners)
    const headers = { 'Content-Type': 'multipart/form-data' };
    return this.http.post(`/wecom-video-admin/custom/group/import`,formData, undefined, { headers });
  }
  
  // 分组列表查询
  public searchCustomGroupAndUser(param: Dict<any>): Promise<any> {
    return this.http.get(`/wecom-video-admin/custom/group/search?keyword=${param.keyword}`);
  }
  
  // 下载模板
  public async downLoadTemplate(filename: string): Promise<any> {
      return this.http.downloadFile('/wecom-video-admin/custom/group/template', {}, filename);
  }
  
  // 导出文件
  public async exportFile(param: Dict<any>): Promise<any> {
    console.log(param)
      return this.http.downloadFile(`/wecom-video-admin/custom/group/${param.customGroupId}/export`, {}, param.filename);
  }

  // 组名可用性校验 
  public customGroupCheck(param: Dict<any>): Promise<any> {
    return this.http.get(`/wecom-video-admin/custom/group/check`, param);
  }

  private trimFields(object: Dict<any>): void {
    forEach(object, (item, index) => {
      if (isObject(item) || isArray(item)) {
        this.trimFields(object[index]);
      } else if (isString(item)) {
        object[index] = item.trim();
      }
    });
  }
}
